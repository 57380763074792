.game_filter_text {
  border-radius: 10px;
  background: #0f243e;
  border-left: 10px solid #0137eb;
  padding: 2rem 3rem;
}

.first_button {
  border-radius: 3px 0 0 3px;
}
.fourth_button {
  border-radius: 0 3px 3px 0;
}

/* Reason why i have alot of impotant here is because of bootstrap  */
.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #1b48e0 !important;
  border: none !important;
}
.nav-link {
  color: #fff !important;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.quarter_filter_button {
  margin-top: 4rem;
  justify-content: flex-end;
}

@media only screen and (max-width: 900px) {
  .quarter_filter_button {
    margin-top: 4rem;
    justify-content: flex-start;
    overflow-x: scroll;
  }
}
