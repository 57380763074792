.register_team_container {
  background: #11111196 url("../../images/svg/registerTeam2.svg") center/cover;
  min-height: 100vh;
}
.register_form_container {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
 
}
.register_form_left_bar,
.register_form_right_bar {
  margin-top: 20px;
  width: 50%;
}
.register_form_right_bar {
  background: #0f243e;
  border-radius: 15px;

  height: auto;
}

.register_form {
  background: #0f243e;
  border-radius: 15px;
  position: relative;
  width: 65%;
  align-content: center;
}
.player_registration_border_bottom_active {
  background-color: #0137eb;
  width: 30px;
  height: 1px;
}
.player_registration_border_bottom {
  background-color: #bbbbbb;
  width: 30px;
  height: 1px;
}

.team_form_container {
  justify-content: space-between;
  height: 860px;
}
.team_form_button {
  position: absolute;
  bottom: 40px;
  
}

@media only screen and (max-width: 900px) {
  .register_form_container {
    flex-direction: column;
  }

  .register_form_left_bar,
.register_form_right_bar {

  width: 100%;
}
}
