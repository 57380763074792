.roaster_header_container {
  background-color: purple;
}
.roaster_team_image {
  display: block;
  margin: auto;
  padding: 8rem 0;
  width: 160px;
  position: relative;
  z-index: 2;
}
