.roaster_info_clip_container {
  height: 400px;
  display: flex;
  justify-content: start;
}
.roaster_info_clip_text {
  position: absolute;
  top: 50%;
  /* left: 4%; */
}
.roaster_player_image {
  width: 350px;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: 80px;
}
.roaster_details_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .roaster_player_image {
    width: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}