.highlight_main_container {
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #1b48e0;

  background: #0f243e;
  margin: 0 20px;
}

.game_highlights_header {
  border-radius: 10px 10px 0px 0px;

  background: #0a101a;
}
.game_highlights {
  margin-bottom: 20px;
}

.highlight_container_section,
.main_video {
  padding: 30px 20px 0px 20px;
}

.main_video {
  border-radius: 10px;
}

.highlight_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  cursor: pointer;
}

.highlight_preview {
  border-radius: 10px;
}

.game_highlights_header_text {
  padding: 20px;
}

.score_page_sides {
  display: flex;
  justify-content: space-between;
}
