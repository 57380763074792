.latest_news_filter {
  background: #0f243e;
  margin: 15px 0;
}

.latest_news_filter_background_light {
  background: #e9ebf8;
}

.filter-class {
  overflow-x: scroll;
}

.filter-class::-webkit-scrollbar {
  width: 0;
}
