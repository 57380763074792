.sht_button_primary {
  display: inline-flex;
  /* padding: 8px 29px 7px 30px; */
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #205df5;
  color: #ffff;
  background: #1b48e0;
}

.sht_button_secondary {
  display: inline-flex;
  padding: 8px 29px 7px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
  background: #00000000;
  color: #ffff;
}

.sht_button_light {
  display: inline-flex;
  padding: 8px 29px 7px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(187, 187, 187, 0.73);
  background: #fff;
}

div .sht_button_disabled {
  background-color: #e6e6e6;
  color: #919191;
  border: #e6e6e6;
}

.sht_button_dark {
  display: inline-flex;
  padding: 8px 29px 7px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(187, 187, 187, 0.73);
  background: #0a101a;
  color: #ffff;
}

.sht_button_highlight {
  color: #1b48e0;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #1b48e0;
  background: #0a101a;
}

.sht_button_empty {
  color: #bcbcbc;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  background-color: #00000000;
}

.sht_button_medium {
  padding: 5px 32px 5px 32px;
  border-radius: 10px;
}
.sht_button_small {
  padding: 8px 29px 7px 30px;
}
.sht_button_large {
  padding: 19px 32px 19px 32px;
}
.sht_button_stats_primary {
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #1b48e0;
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 10px 40px 10px 40px;
}
.sht_button_stats_secondary {
  border: 1px solid #1b48e0;
  background: #0a101a;
  color: #1b48e0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  border-radius: 5px;
  /* line-height: normal; */
  padding: 10px 40px 10px 40px;
}
.sht_button_game_filter_primary {
  border: 1px solid #1b48e0;
  background: #0a101a;
  color: #1b48e0;
  font-family: "Poppins";
  padding: 10px 15px;
}
.sht_button_game_filter_secondary {
  color: #fff;
  font-family: "Poppins";
  background: #1b48e0;
  border: 1px solid #205df5;
  padding: 10px 15px;
}

.sht_button_team_registration {
  background: #0137eb;
  font-weight: 700;
  font-size: 15px;
  padding: 15px 20px;
}

.sht_disabled {
  background: #1e284b;
  border: 1px solid #1e284b;
  color: rgb(86, 86, 86);
}

@media only screen and (max-width: 900px) {
  .sht_button_large {
    padding: 10px 20px 10px 20px;
  }
}
