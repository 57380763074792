.user-icon-container {
  position: relative;
}

.user-icon {
  cursor: pointer;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #f9f9f9;
  min-width: 202px;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1;
}

.dropdown-content button {
  background-color: transparent;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #ddd;
}
