.stats_table_header {
  border-bottom: 1px solid #1b48e0;
}
.stats_table_data {
  border-bottom: 1px solid #1b48e0;
}
.stats_table_container {
  border: 1px solid #1b48e0;
  padding: 1rem;
  /* background-color: #0f243e; */
}
.stats_table_container > table {
  overflow: scroll !important;
}
.stats_button {
  background-color: #0a101a;
  padding: 1.5rem 0;
  border-radius: 0 0 10px 10px;
}
.stats_table_header::-webkit-scrollbar {
  width: 0;
}
