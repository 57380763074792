.roaster_player_info_container{
border-radius: 10px;
border: 1px solid #1B48E0;
background: #0F243E;
max-width: 730px;
height: 200px;
position: relative;
}
.roaster_player_box{
   background: #0F243E;
}
.roaster_info_footer{
position: absolute;
bottom: 5px;
width: 100%;
}
.roaster_info_footer_line{
   background-color: #0137EB;
   height: 1px;
    width: 100%;
}
.sh_news_container{
    display: grid;
    row-gap: 40px;
    column-gap: 80px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-top: 3rem;
}