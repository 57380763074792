.sh_throwback_card_image {
  width: 100%;
  align-items: center;
}

.sh_throwback_card_image_container {
  background: url("../../images/svg/cardBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40rem 0 10rem 0;
}

.sh_throwback_card_text_container {
  text-align: center;
}
