.hero_banner {
  width: 50%;
}

.team_hero_container {
  padding: 50px 0;
}

.cta {
  display: flex;
  flex-direction: "row";
  margin: 20px 0;
  flex-wrap: wrap;
}

.cta_button2 {
  margin-left: 20px;
}

@media only screen and (max-width: 900px) {
  .team_of_week_image {
    display: none;
  }
  .hero_banner {
    width: 100%;
  }
  .cta {
    display: column;
    flex-direction: "row";
    margin: 70px 0;
    flex-wrap: wrap;
  }

  .cta_button2,
  .cta_button {
    margin: 10px;
  }
}
