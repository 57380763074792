.sh_card_body {
  border-radius: 10px;
  background: #0f243e;
  height: auto;
  padding: 1rem;
  width: 382px;
  height: 500px;
  position: relative;
}

.read_more {
  position: absolute;
  bottom: 30px;
}
.card {
  border: none !important;
}
.sh_card_body_main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #0f243e;
  border: none;
  margin: 15px
}

.sh_card_image_card {
  border-radius: 0px;
  background: #0f243e;
}

.sh_card_image {
  border-radius: 10px;
  width: 100%;
  background: #0f243e;
  height: 176px;
}

.sh_card_title,
.sh_card_text,
.sh_card_date,
.sh_card_link {
  padding-top: 10px;
  cursor: pointer;
}

@media (max-width: 992px) {
  /* .sh_card_body {
    width: 500px;
  } */
}
