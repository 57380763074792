.schedule_card_header {
    border-bottom: 1px solid #1B48E0;
}

.schedule_card_container {
    border-radius: 10px;
    background: #0F243E;
}

.team_logo{
    width: 110.074px;
height: 123px;
}

@media only screen and (max-width: 900px) {
    .team_logo{
        width: 50.074px;
    height: 63px;
    }
  }