/* .carousel-control-next-icon, .carousel-control-prev-icon{
    display: none;
} */

.carousel-control-next{
  display: none !important;
}

.sh_hero {
  /* background-color: red; */
}
.sh_hero_comp {
  height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-repeat: repeat;
  background-size: cover;

}

.carousel_container {
  display: flex;
  justify-content: space-between;
}

.carousel_rol_1 {
  width: 65%;
}



/* Medium devices such as tablets (768px and up) */
@media only screen and (max-width: 768px) {
  .carousel_rol_1 {
    width: 100%;
  }
  .sh_hero_comp {
    height: 100%;
    padding: 30px;
  }
}
