.form_list {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 20px;
}
.first_form {
  width: 48%;
}

.image_preview{
  width: 30px;
  height: 30px;
  padding: 2px
}
.team_management {
  margin-bottom: 6rem;
}
.labe_text {
  color: #bcbcbc;
}
.form_list > li {
  color: #bcbcbc;
}
.upload_container {
  display: flex;
  align-items: center;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  width: max-content;
  padding: 15px;
  margin-left: 10px;
  margin-top: 6px;
}
.upload_text {
  margin-left: 15px;
}
.radio_text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #bcbcbc;
}
.form_input_space {
  margin-top: 38px;
}
.form_agreement {
  /* border: 1px solid red; */
  height: 630px;
  overflow: scroll;
}
.form_agreement::-webkit-scrollbar {
  width: 0;
}

.modal-content {
  background-color: #10243E !important;
  padding: 30px;
}

.btn-close{
  background-color: #fff !important;

}
