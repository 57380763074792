.schedule_head {
  background: url("../../images/svg/scheduleBackground.svg"), black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  position: relative;
}

.team_logo {
  width: 279px;
  height: 261px;
}
