.game_header_left {
  height: 168px;
  width: 344px;
  clip-path: polygon(0 0, 44% 1%, 100% 100%, 0% 100%);
  /* background-color: #74b342; */
}

.game_header_container {
  position: relative;

}
.game_header_details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10rem;
  flex-wrap: wrap;
}
.game_header_right {
  clip-path: polygon(51% 0, 100% 0, 100% 100%, 0% 100%);
  height: 168px;
  width: 344px;
  /* background-color: #491697; */
}
