.team_info_schedule_container {
  background: #0f243e;
  border-top: 5px solid #0137eb;
  border-radius: 10px;
}
.team_info_container {
  overflow-x: scroll !important;
}
.team_ifo_image {
  width: 86px;
  height: 81px;
}
.stats_table_cell {
  background-color: #0f243e;
  margin-left: 10px;
}
.stats_table_text {
  border-top: 1px solid #0137eb;
  margin-top: 30px;
}

@media only screen and (max-width: 900px) {
  .team_ifo_image {
    width: 36px;
    height: 31px;
  }
  .game_type_text {
    margin-bottom: 10px;
  }
  .schedule_table_header > .schedule_table_cell{
    padding: 0;
  }
}
