.login_container {
  background: #0f243e;
  height: 100vh;
}
.login_children {
  max-width: 1440px;
  margin: auto;
}
.login_logo_container {
  display: flex;
  justify-content: flex-end;
}
.login_image_container {
  /* display: flex;
  
  justify-content: space-between; */
  align-items: center;
  padding: 0 100px 0 50px;
  justify-content: center;
  width: 50%;

  align-content: center;
}
.form_image {
  margin-top: -100px;
  height: 88vh;
  overflow: hidden;
}
.form_player_image {
  width: 360px;
  position: absolute;
  bottom: 0;
}
.auth_container_facebook {
  margin-left: 29px;
}
.auth_container {
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 60px;
  cursor: pointer;
}
.straight-line {
  width: 40%;
  height: 1px;
  background: white;
}
.sign_up_field {
  width: 48%;
}

