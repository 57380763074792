.team_registration_container {
  background: #0a101a;
  min-height: 100vh;
}
.team_registration_card {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}
.team_registration_index {
  display: flex;
  flex-direction: column;
}
.team_registration_border_bottom {
  background-color: #bbbbbb;
  width: 1px;
  height: 30px;
  margin-left: 20px;
}
.team_registration_border_bottom_active {
  background-color: #0137eb;
  width: 1px;
  height: 30px;
  margin-left: 20px;
}
.register_idx {
  padding: 10px 17px;
  border-radius: 100%;
  border: 1px solid #bbbbbb;
}
.register_idx_active {
  padding: 10px 17px;
  border-radius: 100%;
  border: 1px solid #0137eb;
  background-color: #0137eb;
}
.form_container {
  border-radius: 10px;
  height: 100%;
  background-color: #0f243e;
  position: relative;
  width: 50%;
}
.counter_container {
  padding-right: 5px;
  width: 65%;
}
.form_button_container {
  position: absolute;
  bottom: 36px;
  width: 100%;
}
.form_button {
  display: flex;
  margin: 0 25px;
  justify-content: space-between;
}
.step_counter {
  margin-top: 2rem;
}
.check_active {
  background-color: #0137eb;
}
.return_text {
  cursor: pointer;
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 30px;
}
.next_button {
  display: flex;
  justify-content: end;

  padding: 30px;
  padding-right: 0px;
}

@media only screen and (max-width: 900px) {
  .team_registration_card {
    flex-direction: column;
  }
  .counter_container,
  .form_container {
    width: 100% !important;
    margin-top: 20px;
  }
}
