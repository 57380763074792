.news_content_image_container{
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
}

.news_content_image {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  .news_content_image {
    width: 100%;
    height: 100%;

  }
}