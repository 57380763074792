.score_card_container {
  border-radius: 10px;
  background: #0f243e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.score_card_footer {
  margin-top: 10px;
  border-top: 1px solid #1b48e0;
}
.score_card_button_container {
  border-left: 1px solid #1b48e0;
}

.team_image {
  width: 81.764px;
  height: 60px;
  padding: 10px 20px
}

.row > * {
  padding: 0 !important;
}
