* {
  padding: 0;
  margin: 0;
}

p {
  margin: 0 !important;
}

.sht_text_h1 {
  font-family: "Poppins";
  /* font-size: 55px; */
  font-size: clamp(20px, 5vw, 55px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sht_text_h2 {
  font-family: "Poppins";
  font-size: clamp(15px, 5vw, 50px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sht_text_h3 {
  font-family: "Poppins";
  font-size: clamp(10px, 5vw, 45px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sht_text_h4 {
  font-family: "Poppins";
  font-size: clamp(10px, 5vw, 35px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sht_text_h5 {
  font-family: "Poppins";
  font-size: clamp(5px, 5vw, 25px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sht_text_h6 {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sht_text_p {
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sht_text_card_p {
  font-family: "Poppins";
  font-size: 19px;
  font-weight: 500;
  line-height: normal;
}
.sht_text_card_light {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
}
.sht_text_small {
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sht_text_gray {
  color: #bcbcbc;
}
.sht_text_blue {
  color: #1b48e0;
}
.sht_text_yellow {
  color: #dba30e;
}
.sht_text_link {
  color: #1b48e0;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
}

.sht_text_lighter {
  color: #bcbcbc !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

.sht_text_h3_light {
  color: #fff !important;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.sht_text_white {
  color: #ffff;
}

.sht_text_white_important {
  color: #ffff !important;
}

.sht_text_gray_important {
  color: #bcbcbc !important;
}

.sht_text_active {
  background: #1fe01b;
  color: #ffff !important;
  padding: 7px 33px;
  border-radius: 10px;
  width: max-content;
}

.sht_text_light_mode {
  color: black;
}

.sht_text_strong {
  font-weight: 700;
}
