.header {
  display: flex;
  background: #070d17;
  align-items: center;
  padding: 10px 0px 10px 0px;
  flex-direction: row;
  justify-content: space-between;
}
.header_logo {
  margin-left: 70px;
}
.menu_items {
  display: flex;
  justify-content: center;
}

.menu_items a {
  text-decoration: none;
}

.menu_mobile_items {
  margin-top: 3rem;
}

.mobile_header,
.mobile_profile {
  padding: 20px;
}

.mobile_header {
  background-color: #070d17;
  height: 100vh;
  width: 50%;
  padding: 3rem 0;
}
.nav_container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #070d17a4;
  height: 100vh;
  width: 100%;
}
.hambugger_menu {
  display: none;
}

.logo {
  padding-top: 10px;
}

@media only screen and (max-width: 900px) {
  .menu_items,
  .profile_login {
    display: none;
  }
  .hambugger_menu {
    display: block;
  }

  .header {
    justify-content: space-between;
    padding: 15px 10px 15px 10px;
  }
  .header_logo {
    margin-left: 0;
  }
}
