.team_executive_image {
  position: relative;
  display: inline-block;
}

.executives_image {
  height: 365px;
  width: 230px;
  border-radius: 10px;
  /* position: relative; */
}
.team_executive_text {
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.image-overlay {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #4d0e7106;
}

.team_card_container {
  position: relative;
}
.team_card {
  display: flex;
  flex-wrap: wrap;
/*   justify-content: space-between; */
  gap: 30px;
}

.team_exe_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes slideIn {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  animation: slideIn 0.3s ease;
}
.team_card_container:hover .image-overlay {
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease;
}
@media only screen and (max-width: 900px) {
  .executives_image {
    width: 100%;
  }
  .team_card {
    justify-content: center;
  }
}
