body {
  font-family: "DM Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none !important;
}

.overlay,
.overlay-mini {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 0;
}
.team_image_tiny {
  width: 37px;
  height: 37px;
  margin: 15px;
}

.overlay-mini {
  height: 62%;
}

.header_text {
  position: relative;
  z-index: 2;
}

.sht_container_space {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sht_content_space {
  padding-top: 30px;
  padding-bottom: 30px;
}

.step-indicators-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.footer_input {
  padding: 8px 8px 7px 8px !important;
  width: 100% !important
  ;
}
.subscribe_button {
  margin-left: 0.5rem;
  margin-top: 28px !important;
}
.league_card_container {
  display: flex;
  flex-direction: "row";
  justify-content: "center";
  flex-wrap: wrap;
}

.step-indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.step-indicator.active {
  background-color: black;
}

.horizontal-line {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #cecdcd;
}

.half-color {
  position: absolute;
  top: 0;
  left: 12%;
  height: 100%;
  width: 25%;
  background-color: #1b48e0;
  transform: translateX(-50%);
}

.sht_card_details::-webkit-scrollbar {
  width: 0;
}

.header_with_line_container {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.header_with_line {
  border-bottom: 2px #1c47e0 solid;
  width: 121px;
  text-align: center;
  margin: auto;
}
.line_color_blue {
  border-bottom: 2px #1c47e0 solid;
  width: 121px;
}
.line_color_white {
  border-bottom: 2px #fff solid;
  width: 121px;
}
.line_color_long {
  width: 350px;
}
.line_color_short {
  width: 150px;
}

.header_with_small {
  border-bottom: 2px #fff solid;
  width: 100px;
  text-align: center;
  margin: auto;
}

.dark_mode {
  color: #ececec;
}

.header_mode {
  width: 30px;
  height: 30px;
  flex-direction: flex-end;
  float: right;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.sh_background_dark {
  background: #0a101a;
}

div .sh_background_light {
  background: #ffffff;
}

div .sh_next_match_background_light {
  background: #e9ebf8;
}
div .contract_container {
  height: 100%;
  background: #0f243e;
  border-radius: 10px;
  border: 1px solid #1b48e0;
  align-items: center;

  padding: 20px 20px;
  align-content: center;
  justify-content: center;
margin: 10px 0;
}
div .ytp-large-play-button {
  display: none !important;
  visibility: hidden !important;
  width: 0px !important;
  height: 0px !important;
}

.badge{
  padding: 10px !important;
}

.no_result_container {
  text-align: center;
  height: 25vh;
  justify-content: center;
  margin: auto;
  align-items: center;
  align-content: center;
  margin: auto;
}

.align_right {
  text-align: right;
}
.statistics_text {
  border-top: 1px solid #1b48e0;
  border-bottom: 1px solid #1b48e0;
}
.statistics_image {
  border-bottom: 1px solid #1b48e0;
}
.team_info_stats_header {
  border-bottom: 1px solid #1b48e0;
}
.profile_image {
  width: 85px;
  height: 85px;
}
.progress,
.progress-stacked {
  height: 5px !important;
}

th {
  white-space: nowrap;
  min-width: 1px;
  max-width: 200px;
}
.overflow {
  overflow: scroll;
}

.ytp-large-play-button {
  width: 0px;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.checkbox_options {
  margin-top: 10px;
}
.position_checkbox_container {
  height: 400px;
  overflow: scroll;
}
.position_checkbox_container::-webkit-scrollbar {
  width: 0;
}

@media (max-width: 992px) {
  .line_color_long {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .pagination {
    flex-wrap: wrap;
    justify-content: center;
    
  }
}

