.stats_header {
  /* height: 19156px;÷ */
  background-image: url("../../images/svg/statsBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 50px;
  position: relative;
  height: 382px;
}

.header_text {
  z-index: 1;
}

@media (max-width: 992px) {
  .stats_header {
  padding: 0;
}
}