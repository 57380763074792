.resgister_team_first_card,
.resgister_team_second_card {
  background: rgb(0, 0, 0) url("../../images/svg/team.svg") center/cover;
  width: 47%;
  padding: 98px 56px;
  border-radius: 10px;
  position: relative;
}
.register_button {
  margin-top: 100px;
}
.register_sub_text {
  margin-top: 38px;
}
.resgister_team_second_card {
  background: url("../../images/svg/baseball.svg") center/cover;
}
.overlay {
  position: absolute;
}
.register_text {
  position: relative;
  z-index: 10;
  width: 75%;
}
.sh_team_cards {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .resgister_team_first_card,
  .resgister_team_second_card {
    width: 100%;
    padding: 50px 20px;
  }
  .resgister_team_second_card {
    margin-top: 3rem;
  }
  .sh_team_cards {
    display: block;
  }
  .register_text{
    width: 95%;
  }
}
