.stats_table_header {
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #1b48e0;
}
.stats_table_data {
  border-bottom: 1px solid #1b48e0;
  cursor: pointer;
}
.stats_table_container {
  border: 1px solid #1b48e0;
  padding: 1rem;
  background-color: #0f243e;
  overflow-x: scroll;
}

.standing_border_right_cell {
  border-right: 1px solid #dbdbdb;
}

.second_table {
  margin-top: 3rem;
}

.team_logo_standings {
  width: 80px;
  height: 80px;
  padding: 10px;
}
