.roaster_info_table_head{
    border-bottom: 1px solid #0137EB ;
}
.roaster_info_table_top{
border-top: 1px solid #0137EB ;
}
.roaster_info_table_head_cell {
    padding: 8px;
    width: 20%;
}

.scroll-container::-webkit-scrollbar {
    width: 0px;
}
