.form-check-inline {
  display: block !important;
}
.form-check-label {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #bcbcbc;
  margin-left: 10px;
  margin-bottom: 10px;
  /* padding-bottom: 20px; */
}

.form-check-input {
  width: 25px !important;
  height: 25px !important;
  --bs-form-check-bg: #0a101a !important;
}
.form-check-input[type=radio]:checked {
  --bs-form-check-bg: red !important;
}
.sht_form_legend {
  margin-bottom: 19px;
}
.radio_button {
  gap: 20px;
}
