.team_header {
  background-image: url("../../images/svg/teamBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  position: relative;
}

.league_header_container {
  background: url("../../images/svg/league.svg"), black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  position: relative;
}

.all_teams_header_container {
  background-image: url("../../images/svg/teamBanner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  position: relative;
  height: 382px;
}


