.about_head {
  background: url("../../images/svg/aboutBackground.svg"), black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 70px 0;
  position: relative;
}

.header_text {
  z-index: 1;
}

