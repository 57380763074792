.team_banner_header{
    background-image: url("../../images/svg/teamBackgroundImage.svg");
    background-repeat: no-repeat;
    /* height: 663px; */
    background-size: cover;
    background-position: center;
    padding: 70px 0;
    position: relative;
}
.banner_text{
    z-index: 1;
    text-align: center;
    width: 100%;
    height: 100%;
}