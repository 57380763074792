.player_card_image {
  height:165px;
  width: 224px;
}
.team_card_image {
  width: 140px;
  /* padding-left: 10px; */
  height: 137px;
}
.team_card_wrapper {
  border-radius: 10px;
  background: #0f243e;
}

@media only screen and (max-width: 900px) {
  .team_card_image {
    width: 76px;
    height: 76px;
  }

  .player_card_image{
    height: 100px;
    width: 100px;
  }

  .attribute{
    width: 30%;
    overflow-x: scroll;
  }
}
