.about_feature_body {
  background: #0f243e;
  padding: 50px 10px;
}

.about_featured_medals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 273px;
  border-radius: 10px;
  background: #0a101a;
  padding: 30px 20px;
  margin: 20px;
}
.first_image {
  width: 100%;
}
.second_image,
.third_image {
  width: 47%;
}
.half_part {
  width: 47%;
}
/* .card_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */

@media only screen and (max-width: 900px) {
  .half_part {
    width: 100%;
  }
}
