.weekly_result_box_container {
  border-radius: 10px;
  background: #0f243e;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
}

.right_results,
.left_results {
  display: flex;
  gap: 40px;
}
.left_results {
  min-width: 210px;
}
.team_text {
  border-left: solid 2px #ffff;
  padding-left: 20px;
}

@media only screen and (max-width: 900px) {
  .right_results,
  .left_results {
    display: flex;
    gap: 10px;
  }
  .weekly_result_button {
    width: 100px;
  }
  .left_results_info {
    min-width: 100px;
  }
}
