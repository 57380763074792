.game_card {
  border-radius: 10px;
  background: #0f243e;
  overflow-x: scroll;
}

.open_game_card {
  border: solid 1px #1b48e0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: none;
}

.dropdown_button {
  margin-left: 20px;
}

.game_stats_card_wrapper {
  overflow: hidden;
  background: #0a101a;
  margin: 0 1rem;
  padding-bottom: 3rem;
}
.game_sub_card {
  display: flex;
  justify-content: space-between;
  background: #0f243e;
  padding: 15px;
  margin: 2rem 30px;
  border-radius: 10px;
}

.game_card_container {
  /* border: 1px solid #1b48e0; */
  border-radius: 10px;
  /* padding-bottom: 20px; */
}
.card_team_image {
  margin-right: 20px;
}
.game_sub_down_text {
  margin-top: 10px;
}
.game_card_container ::-webkit-scrollbar {
  width: 0;
}
