.sh_division_page_header_card {
    background-color: #1B48E0;
    height: 50px;
    border-radius: 5px;
    padding: 5px 10px;
}

.sh_division_two_table{
    margin-left: 1rem;
}

th, td{

}
.table>:not(caption)>*>*{
    background-color: #0F243E !important;
} 


.btn-success {
    background-color: #1B48E0 !important;
    border: 1px solid #1B48E0 !important;
}

button {
    border: 1px solid #1B48E0;
}
@media (max-width: 992px) {
    .sh_division_two_table{
        margin-left: 0;
    }
}