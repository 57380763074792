.sh_nextmatch_body {
  background: #0f243e;
}

/* .sh_next_match_background_light {
  background: #e9ebf8;
} */

.sh_nextmatch_body_card {
  margin-bottom: 5rem;
  margin-top: 2rem;
}
.sh_nextmatch_fixture_text {
  border-radius: 5px;
  border: 1px solid #205df5;
  background: #1b48e0;
  margin: 0 25px;
  padding: 10px 0 0 0;
}

.next_match_team_image {
  width: 200px;
  height: 200px;
  padding: 15px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
} /*# sourceMappingURL=af_homepage_style.css.map */

/* Small devices such as large phones (640px and up) */
@media only screen and (max-width: 640px) {
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (max-width: 768px) {
  .next_match_team_image {
    width: 100px;
    height: 100px;
    /* margin: 0 20px; */
  }
}
/* Medium devices such as tablets (768px and up) */
@media only screen and (max-width: 1300px) {
}
