.league_card_body {
  width: 392px;
  height: 279px;
  border-radius: 10px;
  background: #0f243e;
  overflow: hidden;
}

.league_schedule_card {
  justify-content: space-between;
  height: 180px;
}

.league_card_upper {
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #0137eb;
}
.league_card_lower {
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  background: #0a101a;
}

.team_logo_image {
  width: 83.529px;
  height: 80px;
}

.team_details {
  width: 130px;
}

.league_multiple_card{
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 900px) {
  .league_card_body {
    width: 100%;
  }
 .league_multiple_card{
  justify-content: center;
  flex-wrap: wrap;
}
}
