.game_backround_image {
  width: 100%;
  height: 402px;
}
.game_table_container {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #1b48e0;
  background: #0f243e;
 
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}
