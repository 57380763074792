.game_first_table_wrapper {
  padding: 20px;
}
table {
  width: 100%;
  border-radius: 10px;
  background: #0a101a;
}
.table_detail_box {
  width: 30px;
  height: 50px;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}
.table_detail_last_box {
  width: 30px;
  height: 50px;
  border-top: 1px solid #dbdbdb;
}
.table_detail_header_box {
  width: 30px;
  height: 50px;
}
.game_second_table {
  border: 1px solid #1b48e0;
  background: #0a101a;
  padding: 1rem 0;
}
.progress_bar {
  padding-top: 1.3rem;
}
.game_table_filter {
  margin: 0 2rem;
}
.game_switch_text {
  width: 50%;
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer;
}
.game_switch_text_active {
  border-bottom: 5px solid #1b48e0;
  width: 50%;
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer;
}
