.roaster_table_container {
  border-radius: 10px;
  border: 1px solid #1b48e0;
  background: #0f243e;
  margin: 4rem 0;
}
.roaster_table {
  border-radius: 5px;
  background: #0a101a;
  margin: 1em;
}
.stat_table {
  border-radius: 5px;
  background: #0a101a;
  margin: 0.5em;
}
.roaster_table_team_image {
  width: 100px;
  height: 100px;
}
.roaster_table_col {
  width: 20%;
}
.roaster_table_team_image_container {
  display: flex;
  align-items: center;
}

.roaster_table_main th {
  border-bottom: 1px solid #0137eb;
  border-right: 1px solid #dbdbdb;
}
.last_roaster_info {
  border-right: none !important;
}
.roaster_table_main td {
  border-right: 1px solid #dbdbdb;
}

.roaster_table_main t:last-child {
  border-bottom: none;
}



.roaster_single_detail:hover {
  background-color: #ffffff1c;
  cursor: pointer;
}
.roaster_table_main:last-child {
  border-bottom: none;
}
