.team_stats_card_body {
  background: #0f243e;
  width: 380.2px;
  border-radius: 15px;
}
.team_stats_card_title {
  border-bottom: 1px solid #1b48e0;
}
.second_player_name {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
