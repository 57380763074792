.nt_upload_container{
    padding: 20px 0 20px 0;
    border: 2.80569px dashed #7986CB;
    background-color: #E8EAF6;
    border-radius: 20.2063px;
    cursor: pointer;
}

.nt_upload_container_filled{
    background-color: #ffffff;
}

.nt_upload_container li{
    line-height: 10px;
}