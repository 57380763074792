.load_more {
  background-color: #0a101a;
}
.title_head {
  border-bottom: 1px solid #1b48e0;
}
.table_details,
.statistics_details {
  border-bottom: 1px solid #dbdbdb;
}
