.executives_image {
  width: 230px;
  height: 365px;
}

.team_card_container {
  /* margin-right: 40px; */
}
.team_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  place-content: center;
  place-items: center;
  place-self: center;
}
