.input_box {
  border: 1px solid #bbbbbb;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding-left: 20px;
  background-color: #0f243e;
  color: #bbbbbb;
  font-size: 18px;
}
.label_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #bcbcbc;
}
.text_input_style {
  color: #bbbbbb;
}
