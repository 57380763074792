.sh_team_card {
  width: 392px;
  border-radius: 10px;
  background: #0f243e;
}

.league_card_upper,
.league_card_upper_hover {
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #0137eb;
}

.league_card_upper_hover {
  background: #bcbcbc;
}

.team_image_teams {
  width: 144.035px;
  height: 135px;
  margin-right: 10px;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .sh_team_card {
    width: 100%;
   
  }
}