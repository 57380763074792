.team_info_player_stats_container {
  height: 738px;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
}
.team_info_player_stats_left {
  width: 50%;
}
.team_info_player_stats_right {
  background-color: #4c0e71;
  width: 50%;
  padding: 70px;
}
.team_info_player_stats_color {
  background: #dba30e;
  height: 80%;
  width: 198px;
  position: relative;
}
.team_info_player_number {
  display: flex;
  position: absolute;
  left: 30px;
  bottom: -80px;
  z-index: 1;
}
.team_info_second_number,
.team_info_first_number {
  font-size: 300px;
  font-weight: 900;
}
.team_info_second_number {
  color: #dba30e;
}
.team_info_player_image {
  position: absolute;
  top: 5px;
  left: 200px;
  width: 600px;
  height: 738px;
}
.team_info_player_stats_cell {
  background-color: #4c0e71;
}
.carousel-indicators .active {
  background-color: blue !important;
}
.carousel-indicators {
  color: white !important;
}
.team_info_player_table {
  width: 350px;
}
@media only screen and (max-width: 900px) {
  .team_info_player_stats_left {
    width: 100%;
    height: 50%;
  }
  .team_info_player_stats_right {
    width: 100%;
    z-index: 1;
    padding: 40px 10px;
  }
  .team_info_player_stats_container {
    display: block;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .team_info_player_number {
    display: flex;
    position: relative;
    left: 20px;
    bottom: 0px;
  }
  .team_info_second_number,
  .team_info_first_number {
    font-size: 150px;
    font-weight: 900;
  }
  .team_info_player_stats_color {
    display: flex;
    background: #dba30e;
    height: 100% !important;
    width: 50%;
    position: relative;
  }
  .team_info_player_image {
    /* position: relative; */
    top: 0px;
    left: 100px;
    width: 100%;
    height: 400px;
    overflow: hidden !important;
    z-index: 0;
  }
}
