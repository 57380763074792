.sht_ticket_container {
  background-image: url("../../images/svg/ticketBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 109px 0px 109px;
  margin-bottom: 50px;

}

.ticket_side {
  width: 50%;
}

.ticket_side_image {
  width: 100%;
}
@media (max-width: 992px) {
  .sht_ticket_container {
    padding: 0;
    padding-bottom: 80px;
  }
  .ticket_side {
    width: 100%;
  }
  .ticket_side_image {
    display: flex;
    justify-content: center;
  }
}
