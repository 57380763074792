.sht_footer_container {
  padding: 20px;
  padding-bottom: 40px;
}
.sht_footer {
  border-radius: 10px;
  padding: 5rem 1rem;
  background: #0f243e;
  margin: 0 1rem !important;
}

div .sht_footer_col {
  padding: 20px 10px !important;
}

@media (max-width: 992px) {
  .sht_footer_container {
    padding: 0;
    padding-bottom: 40px;
  }
}
