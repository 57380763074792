.first_form_container {
  padding: 55px 45px;
  position: relative;
}



.team_next {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 50px;

}
.form_element {
  padding-bottom: 20px;
}
